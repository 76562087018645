
// Colors
@import 'colors';


main {
    min-height: 450px;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    color: #333;
}

.alert ul {
    margin-bottom: 0;
}


.ck-editor__editable_inline {
    min-height: 400px;
}

h3.ui-title {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin: 10px 0;
}

div.ui-container-form {
    background-color: #fff;
    padding: 20px;
}


.navbar-menu {
    margin-left: 50px;
}
.navbar-menu a {
    font-size: 13px;
    font-weight: bold;
}
.navbar-menu a:hover {
    text-decoration: underline dotted;
    color: $primary !important;
}



.dropdown-menu-selection a {
    font-size: 13px;
}


.ui-link-button {
    color: $primary;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
}
.ui-link-button i {
    font-size: 15px;
}
.ui-link-blank {
    color: #666;
}


// Table

div.ui-table {
    background-color: #fff;
    padding: 20px;
}
div.ui-table table {
    width: 100%;
    color: rgb(151, 151, 151);
    margin: 30px 0;
}
div.ui-table table thead tr th {
    border-bottom: 1px #ccc dotted;
    padding: 5px 0;
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
}
div.ui-table table tbody tr td {
    border-bottom: 1px rgb(228, 228, 228) dotted;
    padding: 10px 0;
}
div.ui-table table tbody tr:hover td {
    background-color: rgba(245, 245, 245, 0.808);
}
div.ui-table table tbody tr td strong {
    color: #333;
}
div.ui-table table .ui-actions a {
    color: rgb(126, 126, 126);
}
div.ui-table table .ui-actions a.trash {
    color: #d00000;
}
div.ui-table table .ui-actions .dropdown.dropstart {
    margin-right: 15px;
}



// LP Dashboard ----------------------------------------------------------------------------------------------------
.ui-dashboard .card-raised h1 {
    font-size: 40px;
    font-weight: 600;
}
.ui-dashboard .card-raised .bi-size-big {
    font-size: 30px;
}
.ui-dashboard .card-raised .card-text-light {
    color: #666;
}



footer {
    color: #ccc;
}





// Mobile 

@media (max-width: 576px) {

    .navbar-menu {
        margin-left: 0;
    }

}